*{
    margin:0;
    padding: 0;
    text-decoration: none;
}
.container{
    display: flex;
}
main{
    width:100%;
    padding: 20px;
}
.top-section{
    display: flex;
    align-items: center;
    padding: 20px 15px;
}
.logo{
    font-size:30px;
}
.bars{
    display: flex;
    font-size: 25px;
    /* margin-left: 50px; */
}
.sidebar{
    background:#e37547;
    color:#fff;
    height: 100vh;
    width: 12rem;
}

.link {
    display: flex;
    color: #FFF;
    padding: 10px 15px;
    gap:15px;
    transition: all 0.5s;
  }
  .link-image{
    display: flex;
    align-items: center;
    justify-content: center;
    height:auto;
    color: #FFF;
    padding: 10px 15px;
    gap:15px;
    transition: all 0.5s;

  }
  img {
    max-width: 100%;
    max-height: 150px;
  }
  .link-image:hover{
    background-color:#d54d2b ;
     color: #fff;
     transition: all 0.5s;

  }
  
  .link:hover {
    background-color:#d54d2b ;
     color: #fff;
     transition: all 0.5s;

  }
 .link_text{
    color: #fff;

  }

  .show-arrow{
    color: #fff;

  }
  .show-arrow:hover{
    background-color:#d54d2b ;
    color: #fff;

  }

  .active{
    background-color:#812c19 ;
    color: #fff;



  }